<template>
  <div class="overlay aerial-survey-menu">
    <div class="title">
      <h3>{{ $t("map.aerialSurvey.title") }}</h3>
    </div>
    <div class="mode">
      <el-form>
        <el-form-item label="Capture Mode">
          <el-row>
            <el-col>
              <el-select
                v-model="mode"
                @change="changeMode"
              >
                <el-option
                  selected
                  :label="$t('map.aerialSurvey.capturePoint')"
                  value="capturePoint"
                />
                <el-option
                  :label="$t('map.aerialSurvey.intervalShooting')"
                  value="intervalShooting"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <div class="description">
      <div class="info">
        <label>{{ $t("map.aerialSurvey.footprint") }}</label>
        <span
          v-if="survey && survey.survey_result"
          class="value"
        >{{ getTransAltitude(survey.survey_result.footprint_lateral_gap) | roundNumber(1) }} x
          {{ getTransAltitude(survey.survey_result.footprint_longitudinal_gap) | roundNumber(1) }} {{ disUnitHint }}</span>
        <span
          v-else
          class="value"
        >--</span>
      </div>
      <div class="info">
        <label>{{ $t("map.aerialSurvey.longitudinal") }}</label>
        <span
          v-if="survey && survey.survey_result"
          class="value"
        >{{ getTransAltitude(survey.survey_result.picture_longitudinal_gap) | roundNumber(1) }} {{ disUnitHint }}</span>
        <span
          v-else
          class="value"
        >--</span>
      </div>
      <div class="info">
        <label>{{ $t("map.aerialSurvey.lateral") }}</label>
        <span
          v-if="survey && survey.survey_result"
          class="value"
        >{{ getTransAltitude(survey.survey_result.picture_lateral_gap) | roundNumber(1) }} {{ disUnitHint }}</span>
        <span
          v-else
          class="value"
        >--</span>
      </div>
      <div class="info">
        <label>{{ $t("map.aerialSurvey.area") }}</label>
        <span
          v-if="survey && survey.survey_result"
          class="value"
        >{{ getTransArea(survey.survey_result.area_size) | roundNumber(1) }} {{ areaUnitHint }}<sup>2</sup></span>
        <span
          v-else
          class="value"
        >--</span>
      </div>
      <div class="info">
        <label>{{ $t("map.aerialSurvey.pictures") }}</label>
        <span
          v-if="survey && survey.survey_result"
          class="value"
        >{{ survey.survey_result.picture_size }} pcs</span>
        <span
          v-else
          class="value"
        >--</span>
      </div>
      <div class="info">
        <label>{{ $t("map.aerialSurvey.altitude") }}</label>
        <span
          v-if="survey && survey.survey_result"
          class="value"
        >{{ getTransAltitude(survey.survey_data.altitude,0) }} {{ disUnitHint }}</span>
        <span
          v-else
          class="value"
        >--</span>
      </div>
    </div>
    <div class="body">
      <el-form>
        <el-form-item label="GSD">
          <el-row>
            <el-col
              :offset="4"
              :span="12"
            >
              <el-input-number
                v-model="configTempory.gsd"
                size="mini"
                :min="2"
              />
            </el-col>
            <el-col
              :span="8"
              class="unit"
            >
              {{ $t("map.unit.mmpx") }}
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="Tilt">
          <el-row>
            <el-col
              :offset="4"
              :span="12"
            >
              <el-input-number
                v-model="configTempory.tilt"
                size="mini"
                :min="-90"
                :max="-40"
              />
            </el-col>
            <el-col
              :span="6"
              class="unit"
            >
              °
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="Angle">
          <el-row>
            <el-col
              :offset="4"
              :span="12"
            >
              <el-input-number
                v-model="configTempory.angle"
                size="mini"
                :min="0"
                :max="90"
              />
            </el-col>
            <el-col
              :span="6"
              class="unit"
            >
              °
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="Overlap">
          <el-row>
            <el-col
              :offset="4"
              :span="12"
            >
              <el-input-number
                v-model="configTempory.overlap"
                size="mini"
                :min="50"
                :max="90"
              />
            </el-col>
            <el-col
              :span="6"
              class="unit"
            >
              %
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="Sidelap">
          <el-row>
            <el-col
              :offset="4"
              :span="12"
            >
              <el-input-number
                v-model="configTempory.sidelap"
                size="mini"
                :min="50"
                :max="90"
              />
            </el-col>
            <el-col
              :span="6"
              class="unit"
            >
              %
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          v-if="configTempory.time_lapse_mode"
          label="Interval"
        >
          <el-row>
            <el-col
              :offset="4"
              :span="12"
            >
              <el-input-number
                v-model="configTempory.photo_duration"
                size="mini"
                :min="0"
                :max="30"
              />
            </el-col>
            <el-col
              :span="6"
              class="unit"
            >
              sec
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button
            :disabled="!isAdmin"
            size="mini"
            class=""
            type="success"
            @click="update"
          >
            {{ $t("map.aerialSurvey.update") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { WaypointStyle } from '@/components/GoogleMaps/MarkerStyle.js';
import { CopyObject } from '@/utils/common.js';
import { DisMeasure, AreaMeasure } from '@/utils/measure';

export default {
  name: 'AerialSurveyMenu',
  props: {
    config: {
      required: true,
      type: Object,
    },
    survey: {
      required: true,
    },
  },
  data: () => ({
    WaypointStyle,
    show: true,
    waypoint: {},
    pathIndex: 0,
    configTempory: {},
    mode: 'capturePoint',
  }),
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
    disUnitHint() {
      return DisMeasure.unit();
    },
    areaUnitHint() {
      return AreaMeasure.unit();
    },
  },
  watch: {
    config() {
      this.$set(this, 'configTempory', CopyObject(this.config));
    },
  },
  mounted() {
    this.$set(this, 'configTempory', CopyObject(this.config));
    this.mode = this.configTempory.time_lapse_mode ? 'intervalShooting' : 'capturePoint';
  },
  methods: {
    update() {
      this.$emit('update', this.configTempory);
    },
    changeMode(mode) {
      this.configTempory.photo_duration = mode === 'intervalShooting' ? 2 : 0;
      this.configTempory.time_lapse_mode = mode === 'intervalShooting';
    },
    getTransAltitude(value, digit = 1) {
      return DisMeasure.display(value, digit, 0);
    },
    getTransArea(value, digit = 1) {
      return AreaMeasure.display(value, digit, 0);
    },
  },
};
</script>

<style lang="scss">
.aerial-survey-menu {
  top: 10px;
  right: 10px;
  left: auto;
  width: 220px;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: black;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.4);

  .title {
    background-color: #d8d8d8;
    height: 43px;
    text-align: center;
    border-radius: 4px 4px 0 0;

    h3 {
      font-size: 14px;
      margin: 0;
      line-height: 43px;
    }
  }

  .mode {
    clear: both;
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: white;
    border-bottom-style: solid;
    border-color: #f5f5f5;

    .el-form {
      padding: 0px 8px;
      .el-form-item {
        margin-top: 10px;
        margin-bottom: 10px;
        label.el-form-item__label {
          color: black;
          width: 110px;
          text-align: left;
          line-height: 15px;
        }

        .el-form-item__label {
          font-size: 12px;
          font-weight: 500;
          opacity: 0.9;
        }

        .el-select {
          padding-top: 10px;
        }

        .el-form-item__content {
          line-height: 30px;
          text-align: center;

          .el-col {
            padding: 0;
          }
        }
      }
    }
  }
  .description {
    padding: 8px;
    background-color: white;

    div.info {
      display: flex;
      flex-direction: row;
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: 12px;
      color: #4f4f4f;

      label {
        flex: 1;
      }

      span.value {
        flex: 1;
      }
    }
  }
  .body {
    clear: both;
    padding-top: 1px;
    .el-form {
      padding: 0px 8px;
      .el-form-item {
        margin-top: 10px;
        margin-bottom: 10px;
        label.el-form-item__label {
          color: black;
          width: 110px;
          text-align: left;
          line-height: 15px;
        }
        input.latLng.value::-webkit-outer-spin-button,
        input.latLng.value::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .el-input-number--mini {
          width: 100px;
        }

        .el-form-item__label {
          font-size: 12px;
          font-weight: 500;
          opacity: 0.9;
        }

        .el-form-item__content {
          line-height: 30px;
          text-align: center;

          .el-col {
            padding: 0;
          }

          .el-col.unit {
            padding-left: 6px;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
