<template>
  <flight-map
    ref="maps"
    :fly-area-guided-click="(event, source) => mapClick(event, source)"
    @operator="o => $emit('operator', o)"
  >
    <fly-router
      ref="path"
      :task="task"
      :each-dis="eachDis"
      @marker-click="index => $emit('update:activateTask', index)"
      @marker-dragstart="$emit('before-input')"
      @marker-dragend="markerDragEnd"
      @middle-marker-click="middleClick"
    />
    <elz-point :task="elz" />
  </flight-map>
</template>

<script>
import { GeographicLonger } from '@/utils/map';
import FlightMap from '@/components/GoogleMaps/FlightMap.vue';
import FlyRouter from '@/components/GoogleMaps/FlyRoute.vue';
import ElzPoint from '@/components/GoogleMaps/ELZPoint.vue';

export default {
  name: 'MissionMapGoogleMaps',
  components: {
    FlightMap,
    FlyRouter,
    ElzPoint,
  },
  props: {
    elz: {
      required: true,
      type: Array,
    },
    activateTask: {
      required: true,
    },
    task: {
      required: true,
      type: Array,
    },
    eachDis: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    mapClick(event, source) {
      let sources = [];
      const position = GeographicLonger(event.latLng);
      const zoneSources = this.findZonesInPosition(position);

      if (source) {
        sources.push(source);
      }
      sources = sources.concat(zoneSources);

      const clickEvent = {
        position,
        sources,
      };
      this.$emit('map-click', clickEvent);
    },
    middleClick(index, event) {
      const source = {
        type: 'middlePoint',
        source: 'mission',
        sequence: index,
      };
      this.mapClick(event, source);
    },
    findZonesInPosition(position) {
      const latLng = new google.maps.LatLng(
        position.latitude,
        position.longitude,
      );

      return this.$refs.maps.findZonesInPosition(latLng);
    },
    markerDragEnd(event) {
      const dragEvent = {
        position: event.position,
        type: 'point',
        source: 'mission',
        sequence: event.index,
      };
      this.$emit('marker-dragend', dragEvent);
    },

  },
};
</script>
