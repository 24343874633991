<template>
  <div class="operators overlay badge" v-bind:style="{top: `${top}px`}">
    <button v-for="(item, index) in menuItem" :key="`${index}-${item}`"
            :class="`image-button ${item}`"
            v-on:click="click(item)" >
            <div class="notification-section__count" v-if="!selectedCount && showing && !clicked">!</div>
    </button>
  </div>
</template>

<script>
 import {logger} from "@/logger/index";
  export default {
    name: 'Elz',
    props: {
      top: {
        required: false,
        type: Number,
        default: 396,
      },
      menuItem: {
        required: false,
        type: Array,
        default: null,
      },
      path: {
        required: true,
        type: Array,
        twoWay: true,
      },
      showing : {
        required:true,
        type :Boolean,
        default : false,
      }
    },
    methods: {
      click(title) {
        logger.debug('emit("elzCallback")');
        // this.$emit("openElzMenu");
        this.$emit("elzCallback");
        this.clicked=true;
        logger.debug('done');
        // this.$emit("waypoint-click", index);
      },
    },
    computed: {
      selectedCount() {
        let r=false;
        this.path.forEach(element => {
          if(element.activate)
            r= true;
        });
        return r;
      },
    },
    data() {
      return {
        clicked:false,
      }
    },
  };
</script>

<style lang="scss">
.operators {
  left: 15px;
  width: 32px;
  display: flex;
  flex-direction: column;
  button {
    cursor: pointer;
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
  }

  button.elz {
    background-image: url("/asserts/icon/map-add-elz-icon.svg");
  }
  
}
.badge {
  position: relative;
  .notification-section__count {
    position: absolute;
    top: -4px;
    /* left: 20px; */
    right: -3px;
    color: white;
    font-size: 11px;
    line-height: 13px;
    background-color: #c90000;
    width: 13px;
    height: 13px;
    text-align: center;
    -webkit-clip-path: circle();
    clip-path: circle();
  }
}
</style>