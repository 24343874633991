<template>
  <div>
    <vl-layer-vector :z-index="100">
      <vl-source-vector :features="pathRange" ref="range"></vl-source-vector>
      <vl-style-box>
        <vl-style-fill :color="fillColor"></vl-style-fill>
      </vl-style-box>
    </vl-layer-vector>
    <vl-layer-vector :z-index="101">
      <vl-source-vector :features="[retuenHomePath]" ref="rtlPath"></vl-source-vector>
      <vl-style-func :factory="returnHomeStyle" />
    </vl-layer-vector>
    <vl-layer-vector :z-index="100">
      <vl-source-vector :features="[path]" ref="path"></vl-source-vector>
      <vl-style-box>
        <vl-style-stroke :color="mainColor"
                         :width="mainPolylineOptions.strokeWeight"
        ></vl-style-stroke>
      </vl-style-box>
    </vl-layer-vector>
  </div>
</template>

<script>
import { CopyObject, HexToRgbA } from '@/utils/common.js';
import { GeographicXyToOl, ExpendSurveyRange, FormatGoogleDashToOl, FormatGoogleLineToOl } from '@/utils/map.js';
import GooglePictureRoute from '@/components/GoogleMaps/PictureRoute.vue'

export default {
  name: 'PictureRoute',
  components: {
  },
  data() {
    return {
      // expaned mainPolylineOptions, pictureRangeOptions, returnHomePolylineOptions, WaypointNormalStyle, WaypointStyle
      ...GooglePictureRoute.data(),
    };
  },
  computed: {
    path() {
      let fullPath = this.survey.waypoints.map(GeographicXyToOl);

      return {
        type: "Feature",
        properties: {
          type: 'polyline',
          source: "PictureRoute-Main",
        },
        geometry: {
          type: "LineString",
          coordinates: fullPath,
        },
      };
    },
    retuenHomePath() {
      let path = this.path.geometry.coordinates;
      return {
        type: "Feature",
        properties: {
          type: 'polyline',
          source: "PictureRoute-Return",
        },
        geometry: {
          type: "LineString",
          coordinates: [path[0], path[path.length - 1]],
        },
      };
    },
    pathRange() {
      let ranges = ExpendSurveyRange(this.survey);

      let geoJSON = [];
      for (let range of ranges) {
        if (range.length === 0) {
          continue;
        }

        let concatedCoordinate = range.concat(range[0]);
        range.concat(range[0]);
        geoJSON.push({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [concatedCoordinate.map(GeographicXyToOl)],
         },
         properties: {
            type: "ploygon",
            source: "PictureRoute",
          }
        });
      }
      return geoJSON;
    },
    mainColor() {
      return HexToRgbA(this.mainPolylineOptions.strokeColor, this.mainPolylineOptions.strokeOpacity)
    },
    fillColor() {
      return HexToRgbA(this.pictureRangeOptions.fillColor, this.pictureRangeOptions.fillOpacity);
    },
  },
  props: {
    survey: {
      type: Object,
      noBind: true,
    },
  },
  watch: {
    survey() {
      this.$refs.range.remount();
      this.$refs.rtlPath.remount();
      this.$refs.path.remount();
    },
  },

  methods: {
    returnHomeStyle() {
      let style = this.returnHomePolylineOptions;
      return function(feature) {
        if (style.icons != null) {
          return FormatGoogleDashToOl(style);
        }
        return FormatGoogleLineToOl(style);
      }
    },
  },
};
</script>
