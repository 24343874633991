<template>
  <span
    id="mission-name"
    v-clickoutside="missionNameHandler"
  >
    <div
      class="overlay name-container"
      @click="toEditMode"
    >
      <el-form
        v-if="isEdit"
        :rules="checkMission"
        @submit.prevent.native="missionNameHandler"
      >
        <el-form-item
          prop="newMissionName"
          class="name-container__edit"
        >
          <el-input
            v-model="newMissionName"
            @click.native="stopPropagation"
          />
        </el-form-item>
      </el-form>
      <h2
        v-else
        class="name-container__display"
      >
        {{ missionName }}
      </h2>
    </div>
  </span>
</template>
<script>
import Clickoutside from 'element-ui/lib/utils/clickoutside';
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import MissionApi from '@/services/api/domain/group/mission';

export default {
  name: 'MissionName',
  directives: { Clickoutside },
  props: {
    missionName: {
      required: false,
      type: String,
      default: '',
    },
    groupId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      newMissionName: this.missionName,
      isEdit: false,
      isGotMissionsList: false,
      missionsList: [],
      checkMission: {
        newMissionName: [
          {
            validator: this.missionNameValid,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    missionsListName() {
      return this.missionsList.map((mission) => mission.name);
    },
    ...mapGetters({
      missionData: 'mission/getMission',
    }),
  },
  methods: {
    toEditMode() {
      this.isEdit = true;
    },
    stopPropagation($event) {
      $event.stopPropagation();
    },
    async missionNameHandler() {
      if (!this.isEdit) {
        return;
      }
      if (!this.isEmpty() && !(await this.isDuplicated())) {
        this.isEdit = false;
        await this.saveName();
      }
    },
    async missionNameValid(rule, val, cb) {
      if (await this.isDuplicated()) {
        cb(this.$t('page.mission.alert.nameExist'));
      } else if (this.isEmpty()) {
        cb(this.$t('page.mission.alert.nameEmpty'));
      } else {
        cb();
      }
    },
    async saveName() {
      const newMission = CopyObject(this.missionData);
      this.setMission({
        ...newMission,
        name: this.newMissionName,
      });
      return true;
    },
    isEmpty() {
      if (this.newMissionName.length) {
        return false;
      }
      return true;
    },
    async isDuplicated() {
      if (!this.isGotMissionsList) {
        await this.getMissionsList();
      }
      return this.missionsListName.includes(this.newMissionName) && this.newMissionName !== this.missionName;
    },
    async getMissionsList() {
      const { data } = await MissionApi.getAll(this.groupId, this.filter);
      this.missionsList = data.missions;
      this.isGotMissionsList = true;
      return true;
    },
    ...mapActions({
      setMission: 'mission/setMission',
    }),
  },
};
</script>

<style lang="scss" scoped>
.name-container {
  top: 14px;
  left: 3.5rem;
  font-size: 14px;
  font-weight: inherit;
  width: 9.5rem;
  padding: 4px;
  border-radius: 4px;
  color: #000000;
  background-color: #cecece;
  display: inline-block;

  &__display {
    margin: 4px 12px 1px;
    font-size: inherit;
    font-weight: inherit;
    border-bottom: 2px dashed transparent;
    padding-bottom: 2px;

    &:hover {
      border-bottom: 2px dashed #757575;
    }
  }

  &__edit {
    margin-bottom: 0;
  }
}
</style>
<style lang="scss">
#mission-name {
  .el-form-item__content {
    line-height: normal;
  }

  .el-input__inner {
    font-size: inherit;
    font-weight: inherit;
    width: 9rem;
    height: 1.5rem;
  }

  .el-form-item__error {
    white-space: nowrap;
    top: 120%;
  }
}
</style>
