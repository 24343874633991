<template>
  <flight-map
    :click="mapClick"
    :flyAreaGuidedClick="mapClick"
    ref="mapRef"
    @operator="o => $emit('operator', o)"
  >
    <template v-if="progress == 'setArea'">
      <polygon-editor 
                  :value="areaGoogle"
                  @input="areaInput"
                  :readonly="false"
                  :marker-icon="icon"
                  :marker-select-icon="icon"
                  :polygon-component="areaComponent"
                  @before-input="$emit('before-input')"
                  ref="zones"
                  @delete-point="e => $emit('delete-point', e)" />
    </template>
    <template v-else-if="progress == 'preview'">
      <picture-route v-if="survey != null"
                      :survey="survey"
                      :task="survey.waypoints"
                      ref="path"
      />
    </template>
  </flight-map>
</template>

<script>
import { WaypointNormalStyle } from '@/components/GoogleMaps/MarkerStyle.js';
import SurveyZone from '@/components/GoogleMaps/SurveyZone.js';
import FlightMap from "@/components/GoogleMaps/FlightMap.vue";
import PictureRoute from '@/components/GoogleMaps/PictureRoute.vue';
import PolygonEditor from '@/components/GoogleMaps/PolygonEditor.vue';

import { GeographicShorter, GeographicLonger } from '@/utils/map.js'

export default {
  name: 'AerialSurveyGoogleMaps',
  components: {
    SurveyZone,
    FlightMap,
    PictureRoute,
    PolygonEditor,
  },
  props: {
    progress: {
      type: String,
      required: true,
    },
    area: {
      type: Array,
      required: true,
    },
    areaComponent: {
      type: Object,
      default() { return SurveyZone; },
    },
    icon: {
      type: Object,
      default() { return WaypointNormalStyle.survey; },
    },
    survey: {
      required: true,
    },
  },
  computed: {
    areaGoogle() {
      return this.area.map(GeographicShorter);
    },
  },
  methods: {
    mapClick(googleEvent) {
      let position = GeographicLonger(googleEvent.latLng);

      let clickEvent = {
        position,
      };
      this.$emit('map-click', clickEvent);
    },
    areaInput(area) {
      this.$emit('update:area', area.map(GeographicLonger));
    },
  },
}
</script>