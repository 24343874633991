<template>
  <flight-map
    ref="mapRef"
    :operator-items="[ 'map-mode', 'center', 'undo', 'clear' ]"
    @map-click="mapClick"
    @operator="o => $emit('operator', o)"
  >
    <template v-if="progress == 'setArea'">
      <polygon-editor
        key="PolygonEditor-Aerial-Survey"
        zone-type="AerialSurvey"
        :zone="areaOl"
        :marker-icon="icon"
        :marker-select-icon="icon"
        :polygon-component="areaComponentOl"
        @update:zone="areaInput"
        @before-input="$emit('before-input')"
        @delete-point="e => $emit('delete-point', e)"
      />
    </template>
    <template v-else-if="progress == 'preview'">
      <picture-route
        v-if="survey != null"
        ref="path"
        :survey="survey"
      />
    </template>
  </flight-map>
</template>

<script>
import { WaypointNormalStyle } from '@/components/GoogleMaps/MarkerStyle.js';
import FlightMap from '@/components/OpenLayers/FlightMap.vue';
import PolygonEditor from '@/components/OpenLayers/PolygonEditor.vue';
import PictureRoute from '@/components/OpenLayers/PictureRoute.vue';

import {
  PolygonsApiToOl, PolygonsOlToApi, GeographicOlToApi, formatNoFlyZoneTypeToOlComponent,
} from '@/utils/map.js';

export default {
  name: 'AerialSurveyOpenLayers',
  components: {
    FlightMap,
    PolygonEditor,
    PictureRoute,
  },
  props: {
    progress: {
      type: String,
      required: true,
    },
    area: {
      type: Array,
      required: true,
    },
    areaComponent: {
      type: String,
      default() { return 'survey'; },
    },
    icon: {
      type: Object,
      default() { return WaypointNormalStyle.survey; },
    },
    survey: {
      required: true,
    },
  },
  computed: {
    areaOl() {
      return {
        id: 'AerialSurvey',
        coordinates: PolygonsApiToOl([this.area]),
      };
    },
    areaComponentOl() {
      return formatNoFlyZoneTypeToOlComponent(this.areaComponent);
    },
  },
  methods: {
    mapClick(event, featureAndLayerList) {
      let clickEvent = {
        position: GeographicOlToApi(event.coordinate),
      };

      const onNoFlyZoneMiddleFeature = featureAndLayerList.find((featureAndLayer) => featureAndLayer.properties.type === 'middlePoint'
          && featureAndLayer.properties.source === 'AerialSurvey');
      if (onNoFlyZoneMiddleFeature) {
        clickEvent = {
          ...clickEvent,
          ...onNoFlyZoneMiddleFeature.properties,
        };
        this.insertPoint(clickEvent);
        return;
      }

      this.$emit('map-click', clickEvent);
    },
    insertPoint(clickEvent) {
      const newArea = this.area.concat(); // copy array by reference
      newArea.splice(clickEvent.sequence + 1, 0, clickEvent.position);
      this.$emit('update:area', newArea);
    },
    areaInput(area) {
      const zone = this.$emit('update:area', PolygonsOlToApi(area.coordinates)[0]);
    },
  },
};
</script>
