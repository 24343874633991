<template>
  <div>
    <template  v-for="(waypoint, index) in path.fullPath">
        <gmap-marker :position="waypoint"
                    :icon="marker"
                    :draggable="false"
                    
                    :key="`elzWaypont${index}`"
        />
      </template>
  </div>
</template>

<script>
import { WaypointNormalStyle, WaypointStyle, WaypointSelectedStyle, WaypointFinishedStyle, WaypointNextStyle } from './MarkerStyle.js'
import { CopyObject } from '@/utils/common.js';
import {logger} from "@/logger/index";
  import {Icon } from '@/components/GoogleMaps/MarkerStyle.js';
export default {
  name: 'ELZPoint',
  components: {
  },
  data() {
    return {
      
      WaypointNormalStyle,
      WaypointStyle,
    };
  },
  computed: {
    path() {
      
      let source = this.task;
      let fullPath = source.map((waypoint, index) => {
        if (waypoint.activate) {
          let value = {
            ...waypoint,
            lat: waypoint.latitude,
            lng: waypoint.longitude,
          };
          delete value.x;
          delete value.y;
          return value;
        }

        
      });
      
      return {
        fullPath,
      };
    },
  },
  watch: {
  },
  async mounted() {
    logger.debug('mounted');
  },
  methods: {
    
  },
  props: {
    task: {
      type: Array,
      twoWay: true,
      noBind: true,
    },
    marker: {
        type: Object,
        default() {
          return Icon.elz;
        },
    },
  },
};
</script>
