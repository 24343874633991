import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1/groups`;

export default {
  routerFind(groupId, data) {
    return axios
      .post(`${apiUrl}/${groupId}/router_plan/router_find`, data)
      .then((response) => {
        return response.data;
      });
  },
  aerialSurvey(groupId, data) {
    return axios
      .post(`${apiUrl}/${groupId}/router_plan/aerial_survey`, data)
      .then((response) => {
        return response.data;
      });
  },
};
