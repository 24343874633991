<template>
  <admin-fillall-layout>
    <component
      :is="mapImplement"
      v-if="captureThumbnail == null"
      ref="maps"
      :elz="map.elz"
      :task="map.path"
      :each-dis="eachDistance"
      :activate-task="map.activatePath"
      @before-input="saveHistory"
      @map-click="mapClick"
      @operator="operator"
      @update:activateTask="openMenu"
      @marker-dragend="markerDragEnd"
      @input="markerInput"
    />
    <map-title :group="group.name" />
    <mission-distance-time
      :distance="distance"
      :duration="estimateTime"
    />
    <back-button @back-click="back" />
    <mission-name
      :mission-name="missionData.name"
      :group-id="group.id"
    />
    <waypoint-menu
      ref="waypointMenu"
      :path="map.path"
      :group="group"
      :allow-counting="isCouningEnable"
      :obstacle-zone="map.activateObstacle"
      :altitude-limit-zone="map.activateAltitude"
      @changeAutoHeading="changeAutoHeading"
      @save="propSave"
      @close="closeMenu"
    />
    <waypoint-list
      ref="waypointList"
      :path="map.path"
      :edit-mode="map.activatePath == null"
      @changeAutoHeading="changeAutoHeading"
      @waypoint-click="openMenu"
      @save="multipleSave"
    />
    <elz
      :path="map.elz"
      :showing="showELZ"
      :menu-item="['elz']"
      @elzCallback="openElzMenu1"
    />
    <elz-waypoint-list
      ref="elzMenu"
      :path.sync="map.elz"
    />
    <default-dialog
      :show-dialog="autoCorrectShow"
      :next-button-text="$t('button.confirm')"
      :center="true"
      :has-cancel="true"
      :show-close="false"
      footer-align="center"
      width="600px"
      @close="autoCorrectShow = false"
      @next="autoCorrect"
    >
      <template slot="main">
        <div class="auto-correct-mesage-container">
          <div class="auto-correct-mesage-body">
            <img
              v-if="correctPath"
              src="/asserts/icon/popup-nofly-zone-icon.svg"
            >
            <img
              v-if="correctAltitude"
              src="/asserts/icon/popup-different-altitudes-icon.svg"
            >
            <img
              v-if="correctBoth"
              src="/asserts/icon/popup-different-altitudes-icon.svg"
            >

            <p v-if="correctPath">
              {{ $t("page.mission.correct") }}
            </p>
            <p v-if="correctAltitude">
              {{ $t("page.mission.correctAltitude") }}
            </p>
            <p v-if="correctBoth">
              {{ $t("page.mission.correctBoth") }}
            </p>
          </div>
        </div>
      </template>
    </default-dialog>
    <default-dialog
      :show-dialog="confirmShow"
      :next-button-text="$t('button.yes')"
      :center="true"
      :has-cancel="true"
      :show-close="false"
      footer-align="center"
      width="600px"
      @close="confirmShow = false"
      @next="saveToAPI"
    >
      <template slot="main">
        <div class="confirm-mesage-container">
          <p>{{ $t("page.mission.saveConfirm") }}</p>
        </div>
      </template>
    </default-dialog>
    <default-dialog
      :show-dialog="noNestShow"
      :next-button-text="$t('button.ok')"
      :center="true"
      :has-cancel="false"
      :show-close="false"
      footer-align="center"
      width="600px"
      @close="noNestShow = false"
      @next="noNestShow = false"
    >
      <template slot="main">
        <div class="confirm-mesage-header">
          <p>{{ $t("page.mission.noNest") }}</p>
        </div>
        <div class="confirm-mesage-container">
          <p>{{ $t("page.mission.toAddNest") }}</p>
        </div>
      </template>
    </default-dialog>

    <default-dialog
      v-if="autoHeadConfirm"
      :show-dialog="true"
      :has-cancel="false"
      :next-button-text="$t('button.yes')"
      :center="true"
      width="558px"
      footer-align="center"
      @next="manualHeading"
    >
      <template slot="main">
        <div class="notification-body">
          {{ $t("page.mission.manualHint") }}
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="main-actions"
          type="info"
          @click="resetHeading"
        >
          {{ $t("button.no") }}
        </el-button>
      </template>
    </default-dialog>
    <save-button
      :cancel-text="$t('button.cancel')"
      :text="$t('button.save')"
      @save="confirmShow = true"
      @cancel="cancel"
    />
    <open-layers-capture-thumbnail
      v-if="captureThumbnail"
      :value="captureThumbnail"
      :promise="captureThumbnailPromise"
      :load-item="[]"
    />
  </admin-fillall-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '../../../utils/common';
import { estimatePathTime, distancePath, getDistance } from '../../../utils/mission';

import NestApi from '../../../services/api/domain/group/nest';
import OpenLayersCaptureThumbnail from '../../../components/OpenLayers/CaptureThumbnail.vue';

import WaypointList from '../../../components/Maps/WaypointList.vue';
import ElzWaypointList from '../../../components/Maps/ELZWaypointList.vue';
import Elz from '../../../components/Maps/Elz.vue';
import WaypointMenu from '../../../components/Maps/WaypointMenu.vue';
import MapTitle from '../../../components/Maps/MapTitle.vue';
import MissionDistanceTime from '../../../components/Maps/MissionDistanceTime.vue';
import BackButton from '../../../components/Maps/BackButton.vue';
import SaveButton from '../../../components/Maps/SaveButton.vue';
import DefaultDialog from '../../../components/DefaultDialog.vue';
import missionApi from '../../../services/api/domain/group/mission';
import PathHeloperAPI from '../../../services/api/domain/group/pathHelper';
import MissionName from './component/MissionName.vue';
import OpenLayersImplement from './Map-OpenLayers.vue';
import GoogleMapsImplement from './Map-GoogleMaps.vue';

export default {
  name: 'MissionMap',
  components: {
    WaypointList,
    WaypointMenu,
    MapTitle,
    MissionDistanceTime,
    SaveButton,
    BackButton,
    DefaultDialog,
    ElzWaypointList,
    Elz,
    OpenLayersCaptureThumbnail,
    MissionName,
  },
  data() {
    return {
      map: {
        path: [],
        elz: [],
        activatePath: null,
        activateObstacle: null,
        activateAltitude: null,
      },
      history: [],
      historyPosition: 0,
      autoCorrectShow: false,
      confirmShow: false,
      correctBoth: false,
      correctPath: false,
      correctAltitude: false,
      noNestShow: false,
      autoHeadConfirm: false,
      captureThumbnail: null,
      captureThumbnailPromise: null,
    };
  },
  computed: {
    mapImplement() {
      switch (this.mapEngine) {
        case 'GoogleMaps':
          return GoogleMapsImplement;
        default:
          return OpenLayersImplement;
      }
    },
    estimateTime() {
      return estimatePathTime(this.map.path);
    },
    eachDistance() {
      const dis = [];
      const totalPath = CopyObject(this.path);
      if (totalPath.length > 0 && totalPath[totalPath.length - 1].command === 'rtl') {
        totalPath.push(this.path[0]);
      }
      for (let i = 0; i < totalPath.length - 1; i += 1) {
        dis.push(getDistance(totalPath[i].x, totalPath[i].y, totalPath[i + 1].x, totalPath[i + 1].y));
      }
      return dis;
    },
    distance() {
      return distancePath(this.map.path);
    },
    ...mapGetters({
      group: 'user/group',
      mapEngine: 'user/mapEngine',
      missionMode: 'mission/getMode',
      missionData: 'mission/getMission',
      missionShow: 'mission/shouldShow',
    }),
    showELZ() {
      if (this.$store.state.mission.mission.id) {
        return false;
      }
      return true;
    },
    path() {
      if (this.map !== null) {
        return CopyObject(this.map.path);
      }
      return [];
    },
    isCouningEnable() {
      return this.$router.history.current.meta.counting === true;
    },
  },
  async created() {
    this.map.path = CopyObject(this.missionData.tasks);
    this.map.elz = CopyObject(this.group.elz);

    if (this.map.path.length === 0) {
      this.addTakeOff();
    }

    this.map.elz.forEach((element) => {
      this.$set(element, 'activate', false);
    });

    if (this.missionData.elz instanceof Array) {
      this.map.elz.forEach((mapElz) => {
        const inMissionElzList = this.missionData.elz.find(
          (missionElz) => missionElz.latitude === mapElz.latitude && missionElz.longitude === mapElz.longitude,
        );
        if (inMissionElzList) {
          mapElz.activate = true;
        }
      });
    }
  },

  methods: {
    getDirection(currentWaypoint, lastWaypoint) {
      const xLength = currentWaypoint.x - lastWaypoint.x;
      const yLength = currentWaypoint.y - lastWaypoint.y;
      let direction = (Math.atan2(yLength, xLength) * 180) / Math.PI;
      direction = Math.round(direction);
      if (direction < 0) {
        direction += 360;
      }
      return direction;
    },
    setHeading(index) {
      if (!this.$refs.waypointList.autoHead) {
        return;
      }
      const currentWaypoint = this.map.path[index];
      if (index - 1 < 0) {
        return;
      }
      const lastWaypoint = this.map.path[index - 1];

      // console.log(currentWaypoint, lastWaypoint, direction);
      this.map.path[index - 1].heading = this.getDirection(currentWaypoint, lastWaypoint);

      const lastIdnex = this.map.path.length - 1;
      if (index !== lastIdnex || this.map.path[lastIdnex].command !== 'rtl') {
        return;
      }
      const takeOff = this.map.path[0];
      this.map.path[index].heading = this.getDirection(takeOff, currentWaypoint);
    },
    async addTakeOff() {
      const nestResponse = await NestApi.getAll(this.group.id);
      const data = {
        sequence: null,
        command: 'takeoff',
        x: this.group.latitude,
        y: this.group.longitude,
        z: 30,
        speed: 4,
        frame: null,
        auto_continue: null,
        photo_duration: 0,
        delay: 0,
        radius: null,
        notify_ai: 0,
      };
      this.map.path.push(data);
      if (nestResponse.data.nests.length === 0) {
        this.noNestShow = true;
      }
      this.$refs.waypointList.activate(0);
      this.$refs.waypointMenu.open(0);
      this.map.activatePath = 0;
    },
    mapClick(event) {
      if (this.map.activatePath != null) {
        return;
      }

      const middle = event.sources.find((source) => source.type === 'middlePoint');
      if (middle) {
        this.saveHistory();
        this.insertFlyRoute(middle.sequence, event.position);
        return;
      }

      const point = event.sources.find((source) => source.source === 'mission');
      if (point) {
        this.openMenu(point.sequence);
        return;
      }

      const fence = event.sources.find((source) => source.source === 'fence');
      if (fence == null) {
        return;
      }

      this.saveHistory();
      let command = 'waypoint';
      const nest = event.sources.find((source) => source.source === 'nests');
      if (nest) {
        command = 'land';
        if (nest.latitude != null) {
          event.position.latitude = nest.latitude;
        }
        if (nest.longitude != null) {
          event.position.longitude = nest.longitude;
        }
      }

      const data = {
        sequence: null,
        command,
        x: event.position.latitude,
        y: event.position.longitude,
        z: 30,
        speed: 4,
        frame: null,
        auto_continue: null,
        delay: 0,
        radius: null,
        photo_duration: 0,
        notify_ai: 0,
      };
      const index = this.map.path.push(data) - 1;
      if (this.checkWaypoint(data)) {
        this.setHeading(index);
      }
    },
    insertFlyRoute(index, position) {
      const data = {
        sequence: null,
        command: 'waypoint',
        x: position.latitude,
        y: position.longitude,
        z: 30,
        speed: 4,
        frame: null,
        auto_continue: null,
        photo_duration: 0,
        delay: 0,
        radius: null,
        notify_ai: 0,
        // heading: this.map.path[index].heading,
      };
      const insertIndex = parseInt(index) + 1;
      this.map.path.splice(insertIndex, 0, data);
      if (this.checkWaypoint(data)) {
        this.setHeading(insertIndex + 1);
      }
    },
    markerDragEnd(event) {
      const waypoint = this.map.path[event.sequence];
      waypoint.x = event.position.latitude;
      waypoint.y = event.position.longitude;
      if (waypoint.command === 'takeoff' || waypoint.command === 'land') {
        this.operator('undo');
        return;
      }

      if (this.checkWaypoint(waypoint)) {
        this.setHeading(event.sequence);
        if (event.index + 1 < this.map.path.length) {
          // set nest waypoint heading
          this.setHeading(event.sequence + 1);
        } else {
          // last waypoint, no next
        }
      }
    },
    markerInput(event) {
      // for vuelayers without Translate interaction
      // it can only return entire path
      console.error('not implemented yet');
    },
    checkWaypoint(waypoint) {
      const zones = this.$refs.maps.findZonesInPosition({
        latitude: waypoint.x,
        longitude: waypoint.y,
      });

      const inFlyZone = zones.find((zone) => zone.source === 'fence');
      if (inFlyZone == null) {
        this.operator('undo');
        this.$showFail('errorMsg.waypointInvalid');
        return;
      }

      const inNoFlyZone = zones.find((zone) => zone.source === 'no_fly_zones' && zone.zoneType === 'no_fly');
      if (inNoFlyZone != null) {
        this.operator('undo');
        this.$showFail('errorMsg.waypointInvalid');
        return;
      }

      if (this.group.max_altitude != null && waypoint.z > this.group.max_altitude) {
        waypoint.z = this.group.max_altitude;
      }

      if (this.group.min_altitude != null && waypoint.z < this.group.min_altitude) {
        waypoint.z = this.group.min_altitude;
      }

      this.checkWaypointByObstacle(waypoint, zones);
      this.checkWaypointByAltitudeLimit(waypoint, zones);
      return true;
    },
    optNotifyAIFlags(paths) {
      const canNext = new Map([
        [0, 1],
        [1, 2],
        [2, 1],
      ]);
      function optfunc(cur, point) {
        if (canNext.get(cur) != point.notify_ai) {
          point.notify_ai = 0;
          return cur;
        }
        return point.notify_ai;
      }
      paths.reduce(optfunc, 0);
      return paths;
    },
    checkWaypointByObstacle(waypoint, zones) {
      const inObstacle = [];
      for (const zone of zones) {
        if (zone.source === 'no_fly_zones' && zone.zoneType === 'obstacle') {
          inObstacle.push(zone);
        }
      }
      if (inObstacle.length === 0) {
        return;
      }

      let minAltitude;
      for (const obstacle of inObstacle) {
        if (minAltitude == null || minAltitude < obstacle.min_altitude) {
          minAltitude = obstacle.min_altitude;
        }
      }
      if (waypoint.z < minAltitude + 1) {
        waypoint.z = minAltitude + 1;
      }
    },
    checkWaypointByAltitudeLimit(waypoint, zones) {
      const inAltitudeLimit = [];
      for (const zone of zones) {
        if (zone.source === 'no_fly_zones' && zone.zoneType === 'altitude_limit') {
          inAltitudeLimit.push(zone);
        }
      }

      let maxAltitude;
      for (const altitude of inAltitudeLimit) {
        if (maxAltitude == null || maxAltitude > altitude.max_altitude) {
          maxAltitude = altitude.max_altitude;
        }
      }
      if (waypoint.z > maxAltitude - 1) {
        waypoint.z = maxAltitude - 1;
      }
    },
    saveHistory() {
      if (this.history.length > this.historyPosition) {
        const toClean = parseInt(this.history.length) - parseInt(this.historyPosition);
        this.history.splice(this.historyPosition, toClean);
      }
      const index = this.history.push(CopyObject(this.map.path));
      this.historyPosition = parseInt(index);
    },
    openElzMenu1() {
      this.$refs.elzMenu.showMenu();
    },
    openMenu(index) {
      if (this.map.activatePath != null) {
        return;
      }
      if (this.$refs.waypointList.multipleEditMode === true) {
        return;
      }
      const position = {
        latitude: this.map.path[index].x,
        longitude: this.map.path[index].y,
      };
      const zones = this.$refs.maps.findZonesInPosition(position);

      const inObstacle = [];
      const inAltitudeLimit = [];

      for (var zone of zones) {
        if (zone.source === 'no_fly_zones' && zone.zoneType === 'obstacle') {
          inObstacle.push(zone);
        }
      }

      for (var zone of zones) {
        if (zone.source === 'no_fly_zones' && zone.zoneType === 'altitude_limit') {
          inAltitudeLimit.push(zone);
        }
      }

      this.map.activateObstacle = null;
      if (inObstacle.length > 0) {
        for (const obstacle of inObstacle) {
          if (this.map.activateObstacle == null || this.map.activateObstacle.min_altitude < obstacle.min_altitude) {
            this.map.activateObstacle = obstacle;
          }
        }
      }

      this.map.activateAltitude = null;
      if (inAltitudeLimit.length > 0) {
        for (const altitude of inAltitudeLimit) {
          if (this.map.activateAltitude == null || this.map.activateAltitude.max_altitude > altitude.max_altitude) {
            this.map.activateAltitude = altitude;
          }
        }
      }
      const selectedNest = [];

      this.map.path.forEach((item) => {
        if (item.command == 'land' || item.command == 'takeoff') {
          selectedNest.push({
            x: item.x,
            y: item.y,
          });
        }
      });
      this.$refs.waypointList.activate(index);
      this.$refs.waypointMenu.open(index, selectedNest);
      this.map.activatePath = index;
    },
    closeMenu(index) {
      this.$refs.waypointList.deActivate(index);
      this.map.activatePath = null;
    },
    propSave(index, value) {
      this.saveHistory();
      if (this.checkWaypoint(value)) {
        this.$set(this.map.path, index, value);
        this.setHeading(index);
        this.closeMenu(index);
        this.$refs.waypointMenu.show = false;
        this.$forceUpdate();
      }
    },
    multipleSave(value) {
      this.saveHistory();
      this.$set(this.map, 'path', value);
      this.$forceUpdate();
    },
    operator(name) {
      switch (name) {
        case 'undo':
          if (this.historyPosition <= 0) {
            return;
          }
          this.historyPosition -= 1;
          this.map.path = this.history[this.historyPosition];
          break;
        case 'clear':
          this.saveHistory();
          this.map.path.splice(0, this.map.path.length);
          this.addTakeOff();
          break;
        default:
          console.error('unkonwn operation');
      }
    },
    cancel() {
      this.setMissionMode(null);
      this.$router.push({
        name: 'missionList',
      });
    },
    back() {
      const arialTypes = ['aerialSingle', 'aerialGrid'];

      for (const taskIndex in this.missionData.tasks) {
        if (this.missionData.tasks[taskIndex].activate) {
          delete this.missionData.tasks[taskIndex].activate;
        }
      }
      this.saveToStatus();

      if (arialTypes.indexOf(this.missionData.type) >= 0) {
        this.$router.push({
          name: 'missionAerialSurveySetArea',
          query: {
            progress: 'preview',
          },
        });
      } else {
        this.$router.push({
          name: 'missionList',
        });
      }
    },
    saveToStatus() {
      const mission = CopyObject(this.missionData);
      mission.tasks = this.map.path;
      this.setMission(mission);
    },
    async saveToAPI() {
      this.saveToStatus();

      const apiData = await this.missionExport(this.map.elz);
      apiData.tasks = this.optNotifyAIFlags(apiData.tasks);

      const loading = this.$loading({
        lock: true,
      });

      if (this.mapEngine === 'OpenLayers') {
        try {
          const thumbnailData = {
            ...apiData,
          };
          const image = await this.createThumbnail(thumbnailData);
          apiData.local_thumbnail = image;
        } catch (error) {
          this.$showFail('No Thumbnail created');
        }
      }

      try {
        if (this.missionMode === 'add') {
          const status = await missionApi.create(this.group.id, apiData);
        } else {
          const status = await missionApi.update(this.group.id, apiData.id, apiData);
        }
        this.$showSuccess(status.msg);
        this.setMissionMode(null);
        this.$router.push({
          name: 'missionList',
        });
      } catch (error) {
        if (
          error.data
          && error.data.meta
          && error.data.meta.error
          && error.data.meta.error.indexOf("Can't find") >= 0
        ) {
          if (
            error.data.meta.error.indexOf("Can't find path") >= 0
            && error.data.meta.error.indexOf("Can't find altitude") >= 0
          ) {
            this.correctPath = false;
            this.correctAltitude = false;
            this.correctBoth = true;
          } else if (error.data.meta.error.indexOf("Can't find path") >= 0) {
            this.correctPath = true;
            this.correctAltitude = false;
            this.correctBoth = false;
          } else {
            this.correctPath = false;
            this.correctAltitude = true;
            this.correctBoth = false;
          }
          this.confirmShow = false;
          this.autoCorrectShow = true;
        } else {
          this.$showFail(error);
        }
      }

      loading.close();
    },
    async autoCorrect() {
      const loading = this.$loading({
        lock: true,
      });
      try {
        const newPathRequest = await PathHeloperAPI.routerFind(this.group.id, {
          path: this.map.path,
        });

        const newPath = newPathRequest.data.path.map((waypoint) => {
          if (waypoint.command == 'waypoints') {
            waypoint.command = 'waypoint';
          }
          return waypoint;
        });

        this.saveHistory();
        this.$set(this.map, 'path', newPath);
        this.autoCorrectShow = false;
        this.correctBoth = false;
        this.correctPath = false;
        this.correctAltitude = false;
      } catch (error) {
        this.$showFail(error);
      }

      loading.close();
    },
    async createThumbnail(data) {
      const capturePromise = new Promise((resolve, reject) => {
        this.captureThumbnailPromise = {
          resolve,
          reject,
        };
        this.captureThumbnail = {
          dataType: 'mission',
          data,
        };
      });
      const result = await capturePromise;
      this.captureThumbnailPromise = null;
      this.captureThumbnail = null;
      return result;
    },
    changeAutoHeading(fromWayPointList) {
      if (!this.$refs.waypointList.autoHead && fromWayPointList) {
        this.autoHeadConfirm = true;
      }
      if (this.$refs.waypointList.autoHead && !fromWayPointList) {
        this.autoHeadConfirm = true;
      }
      // this.$refs.waypointList.autoHead=!this.$refs.waypointList.autoHead;
    },
    resetHeading() {
      if (!this.$refs.waypointList.autoHead) {
        this.$refs.waypointList.autoHead = !this.$refs.waypointList.autoHead;
      }
      this.autoHeadConfirm = false;
    },
    manualHeading() {
      if (this.$refs.waypointList.autoHead) {
        this.$refs.waypointList.autoHead = !this.$refs.waypointList.autoHead;
      }
      this.autoHeadConfirm = false;
    },
    ...mapActions({
      setMissionMode: 'mission/setMode',
      setMission: 'mission/setMission',
      missionExport: 'mission/exportMission',
    }),
  },
};
</script>

<style lang="scss">
.auto-correct-mesage-container {
  text-align: center;

  .auto-correct-mesage-body {
    width: 400px;
    display: inline-block;

    p {
      text-align: left;
    }
  }
}

.confirm-mesage-container {
  text-align: center;
  margin-bottom: 80px;

  p {
    font-size: 16px;
  }
}

.confirm-mesage-header {
  text-align: center;
  margin-bottom: 20px;

  p {
    font-size: 26px;
  }
}
</style>
