<template>
  <div class="overlay elz-waypoint-list" v-show="path.length > 0 && show">
    <div class="elz-waypoint-title">
     {{$t('page.mission.selectelz')}}
    </div>
    
    <div class="elz-waypoint-item-list">
      <div :class="`elz-waypoint-item`" v-for="(item, index) in path" :key="index" v-on:click="click(index)">
        
        <div class="elz-waypoint-index"><img :src="Icon.elzChecked.url" /></div>
        <div class="elz-waypoint-command"><span>{{item.name}}</span></div>
        <div :class="`elz-waypoint-info ${(item.activate) ? ' activate' : ''}`"></div>
      </div>
    </div>
    
    <div class="elz-waypoint-footer">
      <el-button size="mini" class="" type="primary" v-on:click="hide">{{ $t('button.done') }}</el-button>
    </div>
  </div>
</template>

<script>
  import {CopyObject} from '@/utils/common.js'
  import {logger} from "@/logger/index";
  import {Icon} from '@/components/GoogleMaps/MarkerStyle.js'

  export default {
    name: 'ELZWaypointList',
    components: {
    },
    data: () => {
      return {
        Icon,
        show:false,
      };
    },    
    props: {
      path: {
        required: true,
        type: Array,
        twoWay: true,
      },
    },
    mounted() {
      logger.debug(this.path);
    },
    methods: {
      hide(){
        this.show=false;
      },
      showMenu(){
        this.show=true;
      },
      click(index) {
        let data = this.path;
        
        data[index].activate = !data[index].activate;
        this.$emit('update:path', data);
      },
    },
  };
</script>

<style lang="scss">
@import '~@/styles/variables.scss';

$list_top_margin : 10px;
$lsit_title_height: 38px;

.elz-waypoint-list {
  top: 396px;
  font-size: 13px;
  left: 53px;
  width: 186px;
  max-height: 298px;
  // line-height: 20px;
  color: black;

  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: gray;

  .elz-waypoint-title {
    height: 27px;
    background-color: #d8d8d8;
    padding: 9px 8px 9px 8px;
    // margin-bottom: 2px;
    // border-bottom: 1px;
    border-color: #B8B7B9;
    border-radius: 3px 3px 0 0;
    text-align: center;
    .button-group {
      display: flex;
      flex-direction: row;
      height: 18px;

      .static, .middle {
        flex-grow: 1;
      }

      .middle {
        margin: 0 21px;
        display: flex;
        flex-direction: row;
        * {
          flex-grow: 1;
        }
      }
    }

    button {
      padding-left: 7px;
      padding-right: 7px;
    }
    
    // all button using flex get full height of container (button-group)
    button.select-all {
      color: white;
      padding: 0;
    }

    button.edit {
      width: 16px;
      background-image: url('/asserts/icon/missions-wpt-edit-icon-normal.svg');
    }
    button.delete {
      width: 14px;
      background-image: url('/asserts/icon/waypointlist-delete-icon-normal.svg');
    }
    button.delete:disabled {
      background-image: url('/asserts/icon/waypointlist-delete-icon-disable.svg');
    }
    button.pencil {
      width: 14px;
      background-image: url('/asserts/icon/waypointlist-edit-icon-normal.svg');
      margin-left: 15px;
    }
    button.pencil:disabled {
      background-image: url('/asserts/icon/waypointlist-edit-icon-disable.svg');
    }
    button.cancel {
      width: 12px;
      background-image: url('/asserts/icon/waypointlist-cancel-icon-normal.svg');
    }

  }

  .elz-waypoint-footer {
    height: 35px;
    background-color: white;
    padding: 5px 8px 9px 8px;
    margin-bottom: 2px;
    border-bottom: 1px;
    border-color: #B8B7B9;
    border-radius: 3px 3px 0 0;
    text-align: center;
    .button-group {
      display: flex;
      flex-direction: row;
      height: 18px;

      .static, .middle {
        flex-grow: 1;
      }

      .middle {
        margin: 0 21px;
        display: flex;
        flex-direction: row;
        * {
          flex-grow: 1;
        }
      }
    }

    

  }
  .elz-waypoint-item-list {
    border-radius: 0 0 3px 3px;
  }

  .elz-waypoint-item-list {
    // padding to save => 5px
    // zoom button size => 60px
    // zoom button padding botton = 140px
    
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 235px;
    
    .elz-waypoint-item.activate {
      border: 0;
      border-left: 4px;
      border-style: solid;
      border-color: #35bbd1;
      .waypoint-index {
        padding: 0 8px 0 4px;
      }
    }

    .elz-waypoint-item {
      background-color:white;
      height: 35px;
      // margin-bottom: 3px;
      font-size: 13px;
      border-bottom-style: inset;
      .elz-waypoint-index, .elz-waypoint-command, .elz-waypoint-info, .elz-waypoint-selection {
        img, span{
          display: block;
          margin: auto;
          float: left;
          width: inherit;
        }
        height: 35px;
        float: left;
        display: flex;
      }
      .elz-waypoint-index {
        padding: 0 8px;
      }
      .elz-waypoint-command {
        width: 90px;
      }
      .elz-waypoint-info {
        padding: 0 31px;
        background-image: url('/asserts/icon/select-elz-checkbox-unchecked.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
      .elz-waypoint-info.activate{
        padding: 0 31px;
        background-image: url('/asserts/icon/select-elz-checkbox-checked.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
      .waypoint-selection {
        button.select {
          width: 26px;
          margin-left: 9px;
          background-image: url('/asserts/icon/waypointlist-checkbox-unchecked.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        button.select.activate {
          background-image: url('/asserts/icon/waypointlist-checkbox-checked.svg');
        }

        button.select.drone {
          background-image: url('/asserts/icon/waypointlist-drone-icon.svg');
        }
      }
    }

    .elz-waypoint-item:last-child {
      margin-bottom: 0;
    }
    
  } 
}
</style>
