<template>
  <div>
    <gmap-polyline :path="path" :options="mainPolylineOptions" />
    <gmap-polyline :path="retuenHomePath" :options="returnHomePolylineOptions" />
    <template v-for="(path, wIndex) in pathRange">
      <gmap-polygon v-if="path"
                    :path="path.path"
                    :options="path.options"
                    :key="`pathPictureRange-${wIndex}`" />
    </template>
  </div>
</template>

<script>
import { WaypointNormalStyle, WaypointStyle, WaypointSelectedStyle, WaypointFinishedStyle, WaypointNextStyle } from './MarkerStyle.js'
import { CopyObject } from '@/utils/common.js';
import { GeographicXyToGoogle, ExpendSurveyRange } from '@/utils/map.js';

export default {
  name: 'PictureRoute',
  components: {
  },
  data() {
    return {
      mainPolylineOptions: {
        strokeColor: '#1b76be',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        clickable: false,
        zIndex: 10000,
      },
      pictureRangeOptions: {
        strokeWeight: 0,
        fillColor: '#35BBD1',
        fillOpacity: 0.2,
      },
      returnHomePolylineOptions: {
        strokeOpacity: 0,
        icons: [{
          icon: {
            path: 'M 0,-1 0,1',
            strokeColor: '#1b76be',
            strokeOpacity: 1.0,
            scale: 3,
          },
          offset: '0',
          repeat: '15px',
        }],
        zIndex: 10001,
      },
      WaypointNormalStyle,
      WaypointStyle,
    };
  },
  computed: {
    path() {
      let fullPath = this.survey.waypoints.map(waypoint => {
        return {
          lat: waypoint.x,
          lng: waypoint.y,
        }
      });
      return  fullPath;
    },
    retuenHomePath() {
      return [
        this.path[0],
        this.path[this.path.length - 1],
      ];
    },
    pathRange() {
      let rangePath = ExpendSurveyRange(this.survey);
      return rangePath.map((path, index) => {
        if (path.length === 0) {
          return null;
        }
        return {
          options: {
            ...this.pictureRangeOptions,
            zIndex: 1000 + index,
          },
          path: path.map(GeographicXyToGoogle),
        }
      });
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
  },
  props: {
    task: {
      type: Array,
      twoWay: true,
      noBind: true,
    },
    survey: {
      type: Object,
      noBind: true,
    },
  },
};
</script>
