<template>
  <admin-fillall-layout>
    <component
      :is="mapImplement"
      :progress="progress"
      :area.sync="map.area"
      :survey="survey"
      @operator="operator"
      @map-click="mapClick"
      @before-input="saveHistory"
      @delete-point="deletePathPoint"
    />
    <map-title :group="group.name" />
    <template v-if="progress == 'setArea'">
      <back-button
        :mission-name="missionData.name"
        @back-click="back"
      />
      <save-button
        :save-disables="map.area.length < 3"
        :text="$t('page.mission.survey.createRoute')"
        :cancel-text="$t('button.cancel')"
        @save="goPreview"
        @cancel="cancel"
      />
    </template>
    <template v-else-if="progress == 'preview'">
      <mission-distance-time
        v-if="survey != null"
        :distance="survey.distance"
        :duration="survey.duration"
      />
      <aerial-survey-menu
        :config="config"
        :survey="survey"
        @update="getSurvey"
      />
      <back-button
        :mission-name="missionData.name"
        @back-click="backToSetArea"
      />
      <save-button
        :save-disables="survey == null"
        :text="$t('button.confirm')"
        :cancel-text="$t('button.cancel')"
        @save="goWaypoint"
        @cancel="cancel"
      />
    </template>
  </admin-fillall-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common.js';

import GoogleMapsImplement from './AerialSurveySetArea-GoogleMaps.vue';
import OpenLayersImplement from './AerialSurveySetArea-OpenLayers.vue';
import MapTitle from '@/components/Maps/MapTitle.vue';
import MissionDistanceTime from '@/components/Maps/MissionDistanceTime.vue';
import BackButton from '@/components/Maps/BackButton.vue';
import SaveButton from '@/components/Maps/SaveButton.vue';
import AerialSurveyMenu from '@/components/Maps/AerialSurveyMenu.vue';
import { MissionVM } from '@/models/mission';

import PathHeloperAPI from '@/services/api/domain/group/pathHelper.js';

export default {
  name: 'AerialSurveySetArea',
  components: {
    MapTitle,
    MissionDistanceTime,
    SaveButton,
    BackButton,
    AerialSurveyMenu,
  },
  data() {
    return {
      map: {
        area: CopyObject(this.$store.state.mission.mission.area),
      },
      areaHistory: [],
      areaHistoryPosition: 0,
      // for survey preview
      surveyHistory: [],
      surveyHistoryPosition: 0,
      survey: null,
      config: CopyObject(this.$store.state.mission.mission.areaSurveyConfig),
    };
  },
  async mounted() {
    if (this.progress == 'preview' && this.survey == null) {
      await this.getSurvey();
    }
  },
  methods: {
    async getSurvey(config) {
      if (config != null) {
        this.saveHistory();
        this.$set(this, 'config', config);
        this.saveToStatus();
      }

      const loading = this.$loading({
        lock: true,
      });
      try {
        const { data } = await PathHeloperAPI.aerialSurvey(this.group.id, this.surveyApiData);
        this.$set(this, 'survey', data);
      } catch (error) {
        this.$showFail(error);

        if (this.progress == 'preview') {
          this.operator('undo');
        }
      }
      loading.close();
      return true;
    },
    mapClick(event, source) {
      if (this.progress !== 'setArea') {
        return;
      }
      this.saveHistory();
      this.map.area.push(event.position);
    },
    deletePathPoint(index) {
      this.saveHistory();
      this.map.area.splice(index, 1);
    },
    saveHistory() {
      if (this.progress === 'setArea') {
        if (this.areaHistory.length > this.areaHistoryPosition) {
          const toClean = parseInt(this.areaHistory.length) - parseInt(this.areaHistoryPosition);
          this.areaHistory.splice(this.areaHistoryPosition, toClean);
        }
        const index = this.areaHistory.push(CopyObject(this.map.area));
        this.areaHistoryPosition = parseInt(index);
      } else {
        if (this.surveyHistory.length > this.surveyHistoryPosition) {
          const toClean = parseInt(this.surveyHistory.length) - parseInt(this.surveyHistoryPosition);
          this.surveyHistory.splice(this.surveyHistoryPosition, toClean);
        }
        const index = this.surveyHistory.push({
          config: CopyObject(this.config),
          survey: CopyObject(this.survey),
        });
        this.surveyHistoryPosition = parseInt(index);
      }
    },
    undoSetArea() {
      if (this.areaHistoryPosition <= 0) {
        return;
      }
      this.areaHistoryPosition -= 1;
      this.map.area = this.areaHistory[this.areaHistoryPosition];
    },
    undoSurvey() {
      if (this.surveyHistoryPosition <= 0) {
        return;
      }
      const historyPosition = this.surveyHistoryPosition - 1;
      if (this.surveyHistory[historyPosition].survey == null) {
        return;
      }
      this.surveyHistoryPosition = historyPosition;
      this.$set(this, 'config', this.surveyHistory[this.surveyHistoryPosition].config);
      this.$set(this, 'survey', this.surveyHistory[this.surveyHistoryPosition].survey);
    },
    operator(name) {
      switch (name) {
        case 'undo':
          if (this.progress === 'setArea') {
            this.undoSetArea();
          } else {
            this.undoSurvey();
          }

          break;
        case 'clear':
          this.saveHistory();
          if (this.progress === 'setArea') {
            this.map.area.splice(0, this.map.area.length);
          } else {
            const defaultMission = new MissionVM();
            this.getSurvey(defaultMission.areaSurveyConfig);
          }
          break;
        default:
          console.error('unkonwn operation');
      }
    },
    cancel() {
      this.setMissionMode(null);
      this.$router.push({
        name: 'missionList',
      });
    },
    back() {
      this.saveToStatus();

      this.$router.push({
        name: 'missionList',
      });
    },
    backToSetArea() {
      this.progress = 'setArea';
    },
    saveToStatus() {
      const mission = CopyObject(this.missionData);
      mission.area = this.map.area;
      this.setMission(mission);
    },
    async goPreview() {
      this.saveToStatus();

      this.$set(this, 'config', CopyObject(this.missionData.areaSurveyConfig));

      try {
        await this.getSurvey();
      } catch (e) {
      } finally {
        this.progress = 'preview';
      }
    },
    async goWaypoint() {
      const mission = CopyObject(this.missionData);
      mission.tasks = this.survey.waypoints;
      mission.areaSurveyConfig = CopyObject(this.config);
      this.setMission(mission);

      this.$router.push({
        name: 'missionCreateMap',
      });
    },
    ...mapActions({
      setMissionMode: 'mission/setMode',
      setMission: 'mission/setMission',
      missionExport: 'mission/exportMission',
    }),
  },
  computed: {
    mapImplement() {
      switch (this.mapEngine) {
        case 'GoogleMaps':
          return GoogleMapsImplement;
        case 'OpenLayers':
          return OpenLayersImplement;
      }
    },
    progress: {
      get() {
        const queryProgress = this.$route.query.progress;
        if (queryProgress == null) {
          return 'setArea';
        }
        return queryProgress;
      },
      set(now) {
        const originalParam = {
          ...this.$route.query,
        };
        delete originalParam.progress;
        this.$router.push({
          query: {
            progress: now,
            ...originalParam,
          },
        });
      },
    },
    surveyApiData() {
      return {
        ...this.config,
        gsd: this.config.gsd / 10,
        area: this.missionData.area,
      };
    },
    ...mapGetters({
      group: 'user/group',
      mapEngine: 'user/mapEngine',
      missionMode: 'mission/getMode',
      missionData: 'mission/getMission',
      missionShow: 'mission/shouldShow',
    }),
  },
};
</script>

<style lang="scss"></style>
