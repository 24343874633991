<template>
  <div class="overlay distance-time">
    <div class="distance">
      <label>{{ $t('map.distance') }}</label>
      <span class="value">{{ getTransDis(distance) | roundNumber }} {{ disUnitHint }}</span>
    </div>
    <div class="time">
      <label>{{ $t('map.time') }}</label>
      <span class="value">{{ hour | leftPad(2, '0') }}:{{ minute | leftPad(2, '0') }}:{{ second | leftPad(2, '0') }}</span>
    </div>
  </div>
</template>

<script>
import { DisMeasure } from '@/utils/measure';

export default {
  name: 'MissionDistanceTime',
  props: {
    distance: {
      required: true,
      type: Number,
    },
    duration: {
      required: true,
      type: [Number],
    },
  },
  computed: {
    second() {
      return this.duration % 60;
    },
    minute() {
      return Math.floor(this.duration / 60) % 60;
    },
    hour() {
      return Math.floor(this.duration / 60 / 60) % 60;
    },
    disUnitHint() {
      return DisMeasure.unit();
    },
  },
  methods: {
    getTransDis(value, digit = 0) {
      return DisMeasure.display(value, digit, 0, 'round');
    },
  },
};
</script>

<style lang="scss">
.overlay.distance-time {
  color: white;
  top: auto;
  bottom: 15px;
  left: 15px;
  padding-top: 10px;
  padding-bottom: 9px;
  height: 60px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: row;

  .distance, .time {
    padding-left: 12px;
    padding-right: 12px;
  }
  .time {
    border-left: 1px;
    border-left-style: solid;
    border-color: rgba(255, 255, 255, 0.4);
  }

  label, span.value {
    display: block;
  }

  label {
    color: #d0d0d0;
  }

  span.value {
    padding-top: 6px;
  }

}
</style>
