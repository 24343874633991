<template>
  <flight-map :operatorItems="['map-mode', 'center', 'undo', 'clear']"
              @map-click="mapClick"
              @operator="o => $emit('operator', o)"
              ref="maps"
  >
    <fly-route :task="task"
              :id="missionData.id"
              :name="missionData.name"
              :z-index="101"
              @update:mission="missionInput"
              @before-input="$emit('before-input')"
    ></fly-route>
    <!-- elz points -->
    <multiple-point :value="elzPoints"
                    :read-only="true"
                    source="mission-elz"
                    :z-index="100"
    ></multiple-point>
  </flight-map>
</template>

<script>
import { GeographicApiToOl, GeographicOlToApi } from '@/utils/map.js';

import FlightMap from '@/components/OpenLayers/FlightMap.vue';
import MultiplePoint from "@/components/OpenLayers/MultiplePoint.vue";
import FlyRoute from '@/components/OpenLayers/FlyRoute.vue';
import { Icon as MarkerIcon } from '@/components/GoogleMaps/MarkerStyle.js';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MissionMap-OpenLayers',
  components: {
    FlightMap,
    MultiplePoint,
    FlyRoute,
  },
  data() {
    return {}
  },
  props: {
    elz: {
      required: true,
      type: Array,
    },
    activateTask: {
      required: true,
    },
    task: {
      required: true,
      type: Array,
    },
  },
  computed: {
    elzPoints() {
      let list = [];
      for (var land of this.elz) {
        if (land.activate) {
          list.push({
            name: land.name,
            icon: 'elz',
            iconSet: MarkerIcon,
            coordinate: GeographicApiToOl(land)
          });
        }
      }
      return {
        name: 'mission-elz',
        list
      };
    },
    ...mapGetters({
        missionData: 'mission/getMission',
    }),
  },
  methods: {
    findZonesInPosition(position) {
      let coordinate = GeographicApiToOl(position);
      
      let zones = this.$root.$ol.findFeatureOnCoordinate(coordinate)
      return zones.map(zones => zones.properties);
    },
    mapClick(event, featureAndLayerList) {
      let sources = [];
      for (var feature of featureAndLayerList) {
        if (feature.properties != null) {
          sources.push(feature.properties);
        }
      }
      let clickEvent = {
        position: GeographicOlToApi(event.coordinate),
        sources
      };
      this.$emit('map-click', clickEvent);
    },
    missionInput(missionInput) {
      // 這邊全部的程式只為了判斷出使用者真正移動的 Waypoint 是哪一個
      // 詳細請見 MultiplePoint 的 input 的註解
      let changes = missionInput.changed;
      let changeMagnitude = changes.map((taskSequence) => {
        let original = this.task[taskSequence];
        let after = missionInput.list[taskSequence];

        let changes = (original.x - after.x) + (original.y - after.y);
        return {
         sequence: taskSequence,
         changes: Math.abs(changes),
        };
      });
      changeMagnitude.sort((a, b) => b.changes - a.changes);
      let mostChangeSequence = changeMagnitude[0].sequence;
      let dragendEvent = {
        sequence: mostChangeSequence,
        position: {
          latitude: missionInput.list[mostChangeSequence].x,
          longitude: missionInput.list[mostChangeSequence].y,
        },
      };
      this.$emit('marker-dragend', dragendEvent);
    },
  },
}
</script>